<template>
  <div class="container" v-if="!IS_PARTICIPANTS_IN_REQUEST">
    <template v-if="TOURNAMENT.entries.confirmed">
      <team-card :item="team" v-for="team in TOURNAMENT.entries.confirmed" :key="team.id"></team-card>
    </template>
    <template v-if="TOURNAMENT.entries.pending.length">
      <team-card :item="team" v-for="team in TOURNAMENT.entries.pending" :key="team.id"></team-card>
    </template>
  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import TeamCard from "@/components/teamCard";

export default {
  name: "tournamentTeams",
  components: {TeamCard},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
        'TOURNAMENT',
        'IS_PARTICIPANTS_IN_REQUEST'
    ])
  },
}
</script>

<style scoped>
</style>
